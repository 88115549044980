<template>
  <div class="report" style="overflow: hidden; position: relative;">
    <div @click="exportPDF" style="position: absolute; 
    top: 20px; right: 20px; background-color: #459df6; color: white;padding: 5px 10px;border-radius: 4px; ">打印</div>

    <div class="table" id="pdfDom" style="padding-top: 40px;">
      <div class="name" style="text-align: center;margin-top: 40px;margin-bottom: 50px;">
        学生姓名：{{ userInfo.user_name }}
        <span style="margin-left: 10px;" v-if="new_batch == 1">本科批次</span>
        <span style="margin-left: 10px;" v-else>专科批次</span>
      </div>
      <table border v-for="(item, i) in reportTable" :key="i">
        <tbody>
          <tr>
            <td rowspan="9" style="width: 100px;">
              <div class="px">
                平行志愿{{ i + 1 }}
              </div>
              <div v-if="item.name == '暂无'">
              </div>
              <div v-else>
                <div class="fc" v-if="item.tiaoji">服从调剂</div>
                <div class="fc" v-else style="color: red">拒绝调剂</div>
              </div>
            </td>
            <td colspan="6">
              <p class="row" style="color:#adadad">
                <span style="margin-right: 2px;font-size: 18px; color: black;">{{ item.name
                }}</span>
                <span style="font-size: 16px;margin-left: 5px;color: black;">【专业组{{ item.title }}】</span>
                <span v-if="item.school_tip">
                  {{ item.school_tip }} |
                </span>
                <span v-if="item.nature">{{ item.nature }} | </span>
                <span v-if="item.school_type">{{ item.school_type }} | </span>
                <span v-if="item.school_belong"> {{ item.school_belong }} |</span>
                <span v-if="item.address" style=" white-space: nowrap">
                  <el-tooltip class="item" :content="item.address" placement="top-end" effect="light">
                    <span> {{ item.address }} | </span>
                  </el-tooltip>
                </span>
                <span v-if="item.baoyan">保研 | </span>
                <span v-if="item.ruanke">排名：{{ item.ruanke }}</span>
              </p>
              <p style="color:#adadad;margin-top: 10px;font-size: 16px;  white-space: nowrap;overflow: hidden;
  text-overflow: ellipsis; ">
                <span>院校代码：<span style="color: #000000;">{{ item.school_id }}</span></span>
                <span style="margin-left: 15px;">招生计划： <span style="color: #000000;"> {{ item.plan_num }}</span>
                </span>
                <span style="margin-left: 15px;">选科要求：<span style="color: #000000;">{{ item.demand }}</span></span>
                <span style="margin-left: 15px;">录取规则：<span style="color: #000000;">{{ item.lqgz }}</span></span>
              </p>
            </td>
          </tr>
          <tr>
            <td rowspan="7" style="width: 240px;  vertical-align: top;">
              <table class="score-table">
                <tr>
                  <td>年份</td>
                  <td>最低分</td>
                  <td>最低位次</td>
                </tr>
                <tr>
                  <td>2024</td>
                  <td>{{ item.min_score_1 }}</td>
                  <td>{{ item.ranking_1 }}</td>
                </tr>
                <tr>
                  <td>2023</td>
                  <td>{{ item.min_score_2 }}</td>
                  <td>{{ item.ranking_2 }}</td>
                </tr>
                <tr>
                  <td>2022</td>
                  <td>{{ item.min_score_3 }}</td>
                  <td>{{ item.ranking_3 }}</td>
                </tr>
              </table>
            </td>
            <td>专业代号/名称</td>
            <td style="width: 190px">专业详情</td>
            <td style="width: 90px">专业计划</td>
            <td style="width: 90px">学费标准</td>
          </tr>
          <tr v-for="(val, key) in record.specialty[i]" :key="key">
            <td class="td-height">[{{ val.specialty_id }}]{{ val.specialty_name }} </td>
            <td class="td-height">
              <el-tooltip class="item" effect="dark" :content="val.remarks" placement="top-start">
                <div class="remarks"> {{ val.remarks }}</div>
              </el-tooltip>

            </td>
            <td class="td-height">{{ val.plan_num }}</td>
            <td class="td-height">{{ val.tuition }}</td>
          </tr>

          <tr v-for="test in 6 - record.specialty[i].length" :key="test + i + 'dfd'">
            <td style="height: 45px"></td>
            <td style="height: 45px"></td>
            <td style="height: 45px"></td>
            <td style="height: 45px"></td>
          </tr>

        </tbody>
      </table>
      <el-row type="flex" align="middle" v-if="!reportTable">
        <el-col :span="24">
          <div style="margin-top: 20px; text-align: center;"> 暂无志愿表</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      nav: 2,
      new_batch: 1,
      record: {

      },
      reportTable: {},
      len: 48,
      userInfo: {}
    };
  },
  components: {
  },
  created() {
    this.new_batch = this.$route.query.new_batch
    this.getUserInfo()
  },
  methods: {

    //获取用户信息
    getUserInfo() {
      //获取用户信息
      this.$fecth.post("user/getUserInfo", { id: this.$route.query.id }).then((res) => {
        let { code, msg, data } = res;
        if (code == 200) {
          this.userInfo = data
          this.other_subject = this.userInfo.other_subject
          this.getReport()
        } else {
          this.showTips(0, msg);
        }
      });
    },


    getReport() {
      let data = {
        user_id: this.$route.query.id,
        subject: this.userInfo.subject,
        new_batch: this.new_batch,
      }
      this.$fecth.post("volunteer/getUserReport", data).then((res) => {
        if (res.data.record) {
          this.record = JSON.parse(res.data.record)
          this.reportTable = this.record.table.slice(0, 48)
        }
      })
    },

    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },


    exportPDF() {
      this.getPdf(this.userInfo.user_name);
    }
  },
};
</script>

<style scoped lang='less'>
.report {
  width: 1200px;
  margin: 0 auto;
  background: white;
}

.filter {
  padding: 0 52px;
  margin-top: 15px;
  height: 40px;

  ul {
    display: inline-block;
    list-style: none;
    position: relative;
    top: 7px;
  }

  li {
    float: left;
    width: 80px;
    text-align: center;
    margin-left: 20px;
    border: 1px solid #707070;
    border-radius: 19px;
    padding: 2px 13px;
  }

  li:hover {
    background: #459df6;
    color: white;
    border: #459df6;
  }
}

.top {
  margin-top: 20px;
  border-bottom: 4px solid #459EFF;
  height: 40px;
}

.right {
  float: right;
  height: 36px;

  ul {
    display: inline-block;
    list-style: none;
  }

  li {
    float: left;
    margin-left: 10px;
    height: 36px;
    padding: 17px;
    line-height: 3px;
    border-radius: 5px 5px 0 0;
  }

  li:hover {
    color: white;
    background: #459EFF;
    border-bottom: 1px solid #459EFF;
  }

}

.nav {
  color: white;
  background: #459EFF;
  border-bottom: 1px solid #459EFF;
}





.table {
  padding: 0 40px;
  margin: 0 auto;

  table {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    .th {
      font-size: 16px;
      background: #f4f7fc;
      line-height: 35px;

      .name {
        font-size: 18px;
        font-weight: bold;
      }

      span {
        margin-right: 15px;
      }

      .col {
        margin-left: 0;
        color: #459df6;
      }
    }

    .th,
    td {
      line-height: 35px;
      padding: 5px 13px;
    }
  }

  .del {
    text-align: center;
    margin-bottom: 10px;
  }

  .huanwei {
    text-align: center;
    margin-bottom: 10px;
  }
}

.px {
  margin-top: 20px;
  text-align: center;
  font-size: 14;
  margin-bottom: 10px;
}

.fc {
  text-align: center;
  color: #459df6;
  margin-bottom: 10px;
}

.score-table {
  font-size: 12px;
  margin-top: 24px !important;
  color: #B5B3B3;
  border: 0 !important  ;
 

  td {
    padding: 0;
    text-align: center;

  }
}
</style>


<style lang="less">
.mojarDialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>